import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Box, IconButton, Button, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';
import './App.css';

function App() {
  const [information, setInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [view, setView] = useState('all'); // Track current view: 'all', 'accepted', or 'refused'

  useEffect(() => {
    fetchInformation(view);
  }, [view]);

  const fetchInformation = async (type) => {
    try {
      setLoading(true);
      let url = 'https://sadia.symloop.com/api/information';
      if (type === 'accepted') {
        url = 'https://sadia.symloop.com/api/information/accepted';
      } else if (type === 'refused') {
        url = 'https://sadia.symloop.com/api/information/refused';
      }

      const response = await axios.get(url);
      setInformation(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching information:', error);
      setLoading(false);
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setLoading(true);
      await axios.put(`https://sadia.symloop.com/api/information/${id}/status`, { status });
      fetchInformation(view); // Refresh the list after updating status
    } catch (error) {
      console.error(`Error updating status to ${status}:`, error);
      setLoading(false);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const exportToExcel = (data, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Records");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const exportAccepted = async () => {
    const response = await axios.get('https://sadia.symloop.com/api/information/accepted');
    exportToExcel(response.data, 'Accepted_Records');
  };

  const exportRefused = async () => {
    const response = await axios.get('https://sadia.symloop.com/api/information/refused');
    exportToExcel(response.data, 'Refused_Records');
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Sadia</h1>
        <div className="header-buttons">
          <Button 
            className="header-btn" 
            onClick={() => setView('all')}
            sx={{ 
              backgroundColor: '#1976d2', 
              color: 'white', 
              margin: '0 5px',
              '&:hover': { backgroundColor: '#115293' },
            }}
          >
            Pending
          </Button>
          <Button 
            className="header-btn" 
            onClick={() => setView('accepted')}
            sx={{ 
              backgroundColor: '#4caf50', 
              color: 'white', 
              margin: '0 5px',
              '&:hover': { backgroundColor: '#357a38' },
            }}
          >
            Accepted
          </Button>
          <Button 
            className="header-btn" 
            onClick={() => setView('refused')}
            sx={{ 
              backgroundColor: '#f44336', 
              color: 'white', 
              margin: '0 5px',
              '&:hover': { backgroundColor: '#d32f2f' },
            }}
          >
            Refused
          </Button>
          <Button
            onClick={exportAccepted}
            sx={{
              backgroundColor: '#4caf50',
              color: 'white',
              marginLeft: '20px',
              '&:hover': { backgroundColor: '#357a38' },
            }}
          >
            Export Accepted to Excel
          </Button>
          <Button
            onClick={exportRefused}
            sx={{
              backgroundColor: '#f44336',
              color: 'white',
              marginLeft: '20px',
              '&:hover': { backgroundColor: '#d32f2f' },
            }}
          >
            Export Refused to Excel
          </Button>
        </div>
      </header>

      <div className="grid-container">
        {information.length > 0 ? (
          information.map(info => (
            <div key={info.id} className="card">
              <img 
                src={`https://sadia.symloop.com/${info.image}`} 
                alt={info.name} 
                className="info-image"
                onClick={() => handleImageClick(`https://sadia.symloop.com/${info.image}`)}
              />
              <div className="card-content">
                <p><strong>Name:</strong> {info.name}</p>
                <p><strong>Email:</strong> {info.email}</p>
                <p><strong>Phone Number:</strong> {info.nationality}</p>
                <p><strong>City:</strong> {info.city}</p>
                <p><strong>Status:</strong> {info.status}</p>
              </div>
              {view === 'all' && (
                <div className="card-actions">
                  <Button 
                    className="accept-btn" 
                    onClick={() => updateStatus(info.id, 'accepted')}
                    sx={{ 
                      backgroundColor: '#4caf50', 
                      color: 'white', 
                      '&:hover': { backgroundColor: '#357a38' },
                    }}
                  >
                    Accept
                  </Button>
                  <Button 
                    className="refuse-btn" 
                    onClick={() => updateStatus(info.id, 'refused')}
                    sx={{ 
                      backgroundColor: '#f44336', 
                      color: 'white', 
                      '&:hover': { backgroundColor: '#d32f2f' },
                    }}
                  >
                    Refuse
                  </Button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No records found.</p>
        )}
      </div>

      {/* Loading overlay */}
      {loading && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            bgcolor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 9999,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      {/* Modal for displaying the image */}
      <Modal
        open={!!selectedImage}
        onClose={handleCloseModal}
        aria-labelledby="enlarged-image-modal"
        aria-describedby="modal-to-show-enlarged-image"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 2,
            overflow: 'hidden',
            position: 'relative',
            '@media (max-width: 600px)': {
              p: 1,
            },
          }}
        >
          <IconButton 
            onClick={handleCloseModal} 
            sx={{ 
              position: 'absolute', 
              top: '20px', 
              right: '30px', 
              color: 'white', 
              bgcolor: 'rgba(0, 0, 0, 0.5)', 
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.8)',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            component="img"
            src={selectedImage}
            alt="Enlarged"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              borderRadius: 2,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default App;
